import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Drawer,
    FormControl,
    FormControlLabel,
    Grid,
    TextField,
    Radio,
    RadioGroup,
    OutlinedInput,
    FormHelperText,
    Tooltip,
    Typography,useMediaQuery
} from '@mui/material';
import { IconSettings } from '@tabler/icons';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import SubCard from 'ui-component/cards/SubCard';

import useKeyConfig from '../../hooks/useKeyConfig';
import { API_MODEL } from '../../utils/aiapi';

// concat 'px'
function valueText(value) {
    return `${value}px`;
}

// ==============================|| LIVE CUSTOMIZATION ||============================== //

const Setting = ({ open, setOpen, ...opts }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const { keyConfig, setkeyConfig } = useKeyConfig();
    // const [tmpkeyConfig, settmpkeyConfig] = useState(keyConfig);
    const handleToggle = () => {
        setOpen(!open);
    };
    const handleTypeChange = (event) => {
        keyConfig.openAI.type = event.target.value;
        keyConfig.openAI.key = keyConfig.openAI[keyConfig.openAI.type + 'key'];
        // console.log(keyConfig.openAI);
        // console.log(keyConfig.openAI[keyConfig.openAI.type + 'key'])
       // settmpkeyConfig({ ...tmpkeyConfig });
        setkeyConfig({ ...keyConfig });
    };
    const handleKeyChange = (event) => {
        // TODO 要修改成 不同模型设置
        keyConfig.openAI.key = event.target.value;

        if (keyConfig.openAI.type == 'direct' || keyConfig.openAI.type == 'agent') {
            keyConfig.openAI['direct' + 'key'] = event.target.value;
            keyConfig.openAI['agent' + 'key'] = event.target.value;
        } else {
            keyConfig.openAI[keyConfig.openAI.type + 'key'] = event.target.value;
        }
         keyConfig.commAI=keyConfig.openAI
        // settmpkeyConfig({ ...tmpkeyConfig });
        setkeyConfig({ ...keyConfig });
    };
    const handleKeyChange2 = (event) => {
        // TODO 要修改成 不同模型设置
        keyConfig.weiYouAI=keyConfig.weiYouAI || {};
        keyConfig.weiYouAI.key = event.target.value;

        if (keyConfig.openAI.type == 'direct' || keyConfig.openAI.type == 'agent') {
            keyConfig.weiYouAI['direct' + 'key'] = event.target.value;
            keyConfig.weiYouAI['agent' + 'key'] = event.target.value;
        }
        setkeyConfig({ ...keyConfig });
    };
    
    return (
        <>
            <Drawer
                anchor="left"
                onClose={handleToggle}
                open={open}
                PaperProps={{
                    sx: {
                        width: !matchUpMd ? 'calc(100vw - 66px)' : 'calc(50vw)',
                    }
                }}
            >
                <PerfectScrollbar component="div">
                    <Grid container sx={{ p: 3 }}>
                        <Grid item xs={12}>
                            {/* font family */}
                            <SubCard title={t("Home.JieRuMoShi")}>
                                <FormControl>
                                    <RadioGroup
                                        aria-label="font-family"
                                        value={keyConfig.openAI.type}
                                        onChange={handleTypeChange}
                                        name="row-radio-buttons-group"
                                    >
                                        {API_MODEL.map((item, i) => (
                                            <div key={i}>
                                                <FormControlLabel 
                                                    
                                                    value={item.id}
                                                    control={<Radio />}
                                                    label={t("Home."+item.label)}
                                                    sx={{
                                                        '& .MuiSvgIcon-root': { fontSize: 28 },
                                                        '& .MuiFormControlLabel-label': { color: theme.palette.grey[900] }
                                                    }}
                                                />
                                                <FormHelperText   sx={{ fontSize: 12 }}>{t("Home."+item.desc)}</FormHelperText>
                                            </div>
                                        ))}
                                    </RadioGroup>
                                  
                                </FormControl>
                                {keyConfig.openAI.type !== 'test' && (
                                    <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField value={keyConfig.openAI.key} onChange={handleKeyChange} label="请填写你申请到的key" />
                                        <FormHelperText sx={{ fontSize: 12 }}>请输入正确的key可以正常使用了</FormHelperText>
                                    </FormControl>
                                )}
                                  {keyConfig.openAI.type == 'direct' && (
                                        <FormControl sx={{ mt: 2, width: '100%' }}>
                                        <TextField value={keyConfig.weiYouAI.key} onChange={handleKeyChange2} label="请填写你申请到的weiYouAIkey" />
                                        <FormHelperText sx={{ fontSize: 12 }}>请输入正确的weiYou key可以正常使用了</FormHelperText>
                                    </FormControl>
                                )} 
                            </SubCard>
                        </Grid>
                    </Grid>
                </PerfectScrollbar>
            </Drawer>
        </>
    );
};

export default Setting;
