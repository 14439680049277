import dashboard from './dashboard';
import groups from './groups';



// ==============================|| MENU ITEMS ||============================== //
//  items: [dashboard, pages, utilities, other]
const menuItems = {
    items: [dashboard ] //   faucets, chains,groups  ,pages,files, utilities, other
};

export default menuItems;
