import React, { createContext, useCallback, useEffect, useState,useMemo } from 'react';
import { openAIParams, getLocalValue, setLocalValue } from '../../utils/aiapi';
import { api } from '../../utils/api';
import markdownIt from 'markdown-it';
// import markdownItHighlightJs  from markdown-it-highlightjs';

export const Context = createContext({
    funName:'',
    setFunName:null,
    siderMemu:null,
    setSiderMemu:null,
    save:null, 
    setSave:null,
    search: '',
    setSearch: null,
    sendMsg: '',
    setSendMsg: null,
    isApiLoading: false,
    subjectType: '',
    setSubjectType: null,
    subject: '',
    setSubject: null,
    dissample: true,
    setDisSample: null,
    mutchat: null,
    setMutchat: null,
    entersend: null,
    setEntersend: null,
    showchat: null,
    setShowchat: null,
    AIParams: null,
    setAIParams: null,
    AISamples: null,
    setAISamples: null,
    clearmsg: null,
    setClearmsg: null,
    chatscroll: null,
    setChatscroll: null,
    speech: null,
    setSpeech: null,
    isspeak: null,
    setIsspeak: null,
    chatlist: null,
    setChatlist: null,
    md: null,
    setMd: null,
    curchatFileId: null,
    setCurchatFileId: null,
    curdocFileId: null,
    setCurdocFileId: null,
    curimgFileId: null,
    setCurimgFileId: null,
    curcodeFileId: null,
    setCurcodeFileId: null,
    chatFileList: null,
    setChatFileList: null,
    docFileList: null,
    setDocFileList: null,
    imgFileList: null,
    setImgFileList: null,
    codeFileList: null,
    setCodeFileList: null,
    curDocChatList: null,
    setCurDocChatList:null,
    curImgChatList: [],
    setCurImgChatList: null,
    curCodeChatList: null,
    setCurCodeChatList: null,
    sessionObj:null,
    addSession:null,
    updateSession:null,
    selectSession:null,
    deleteSession:null
});

const FilterProvider = ({ children }) => {
    // const [walletModal, setWalletModal] = useState(false)
    const [funName, setFunName] = useState(''); // 功能s
    const [siderMemu, setSiderMemu] = useState(false); // 判断显示功能菜单，还是会话/文档/图片
    const [save, setSave] = useState(false); // 是否需要保存
    const [curchatFileId, setCurchatFileId] = useState(getLocalValue('curchatFileId') || { id: 'chatlist', title: '' }); // 当前聊天会话记录ID
    const [curdocFileId, setCurdocFileId] = useState(getLocalValue('curdocFileId') || { id: 'doclist', title: '' }); // 当前文档会话记录ID
    const [curimgFileId, setCurimgFileId] = useState(getLocalValue('curimgFileId') || { id: 'imglist', title: '' }); // 当前图片会话记录ID
    const [curcodeFileId, setCurcodeFileId] = useState(getLocalValue('curcodeFileId') || { id: 'codelist', title: '' }); // 当前代码会话记录ID

    //
    const [chatFileList, setChatFileList] = useState(getLocalValue('chatFileList')|| [{ id: 'chatlist', title: '' }]); // 对话标题记录
    const [docFileList, setDocFileList] = useState(getLocalValue('docFileList') || [{ id: 'doclist', title: '' }]); // 文档标题编辑记录
    const [imgFileList, setImgFileList] = useState(getLocalValue('imgFileList') || [{ id: 'imglist', title: '' }]); // / 图片标题会话记录
    const [codeFileList, setCodeFileList] = useState(getLocalValue('codeFileList') || [{ id: 'codelist', title: '' }]); // / 代码标题会话记录

    const [chatlist, setChatlist] = useState(getLocalValue(curchatFileId?.id || 'chatlist') || []); // 当前记录
    const [curDocChatList, setCurDocChatList] = useState(getLocalValue(curdocFileId.id || 'doclist') || {}); // 当前记录
    const [curImgChatList, setCurImgChatList] = useState(getLocalValue(curimgFileId.id || 'imglist') || []); // 当前记录
    const [curCodeChatList, setCurCodeChatList] = useState(getLocalValue(curcodeFileId.id || 'codelist') || ''); // 当前记录

    const [search, setSearch] = useState(''); // / 正在输入的文本
    const [subjectType, setSubjectType] = useState('openAI');
    const [subject, setSubject] = useState(openAIParams[0] || {});
    const [sendMsg, setSendMsg] = useState(''); // 准备发送的文本
    const [isApiLoading, setApiLoading] = useState(false);
    const [dissample, setDisSample] = useState(false);
    const [mutchat, setMutchat] = useState(false); // 带会话信息
    const [entersend, setEntersend] = useState(true); // 回车发送
    
    const [AIParams, setAIParams] = useState(openAIParams || []);
    const [AISamples, setAISamples] = useState([]);
    const [clearmsg, setClearmsg] = useState(false);
    const [clearMsgOpen, setClearMsgOpen] = useState(false);
    const [chatscroll, setChatscroll] = useState(false);
    const [speech, setSpeech] = useState(null);
    const [isspeak, setIsspeak] = useState(false);
    const [speechtxt, setSpeechtxt] = useState('');
    const [showchat, setShowchat] = useState(false);
    const [md, setMd] = useState(null);

    // 变化时,保存记录
    useEffect(() => {
        if (!save) return;
        if (funName!='chat') return;
        const tmpchatlist = chatlist.filter((item) => item.aktype != 'sample' && item.aktype != 'error');
        console.log('curchatFileId',curchatFileId,'tmpchatlist', tmpchatlist);
        console.log('funName',funName,'save', save)
        setLocalValue(curchatFileId.id, tmpchatlist);
        setSave(false);
    }, [chatlist]);
    //切换会话
    useEffect(() => {
        setChatlist(getLocalValue(curchatFileId?.id || 'chatlist') || []);
        setLocalValue('curchatFileId', curchatFileId);
    }, [curchatFileId]);
    // 会话变更
    useEffect(() => {
        if (chatFileList && chatFileList.length==0 ) {
            chatFileList=[{ id: 'chatlist', title: '' }];
        }
        setLocalValue('chatFileList', chatFileList);
    }, [chatFileList]);

    useEffect(() => {
        //if (!save) return;
        if (funName!='doc') return;
        setLocalValue(curdocFileId.id, curDocChatList);
        // setSave(false);
    }, [curDocChatList]);
    //切换会话
    useEffect(() => {
        setCurDocChatList(getLocalValue(curdocFileId?.id || 'doclist') || {});
        setLocalValue('curdocFileId', curdocFileId);
    }, [curdocFileId]);
    // 会话变更
    useEffect(() => {
        if (docFileList && docFileList.length==0 ) {
            docFileList=[{ id: 'doclist', title: '' }];
        }
        setLocalValue('docFileList', docFileList);
    }, [docFileList]);

    useEffect(() => {
        if (!save) return;
        if (funName!='img') return;
        const tmpchatlist = curImgChatList.filter((item) => item.aktype != 'sample' && item.aktype != 'error');
        setLocalValue(curimgFileId.id, tmpchatlist);
       setSave(false);
    }, [curImgChatList]);
    //切换会话
    useEffect(() => {
        setCurImgChatList(getLocalValue(curimgFileId?.id || 'imglist') || []);
        setLocalValue('curimgFileId', curimgFileId);
    }, [curimgFileId]);
    // 会话变更
    useEffect(() => {
        if (imgFileList && imgFileList.length==0 ) {
            imgFileList=[{ id: 'imglist', title: '' }];
        }
        setLocalValue('imgFileList', imgFileList);
    }, [imgFileList]);

    useEffect(() => {
        if (!save) return;
        if (funName!='code') return;
        setLocalValue(curcodeFileId.id, curCodeChatList)
        setSave(false);
        
    }, [curCodeChatList]);
    useEffect(() => {
        setCurCodeChatList(getLocalValue(curcodeFileId?.id || 'codelist') || []);
        setLocalValue('curcodeFileId', curcodeFileId);
        
    }, [curcodeFileId]);

    // 会话变更
    useEffect(() => {
        if (codeFileList && codeFileList.length==0 ) {
            codeFileList=[{ id: 'codelist', title: '' }];
        }
        setLocalValue('codeFileList', codeFileList);
    }, [codeFileList]);

    //// 会话函数
     /*
    const sessionObj = () => {
        if (funName=='img') {
              return {setCurFileId:setCurimgFileId,curFileId:curimgFileId, setFileList: setImgFileList,fileList: imgFileList,setChatlist:setCurImgChatList}
          } else if (funName=='doc') {
              return {setCurFileId:setCurdocFileId,curFileId:curdocFileId, setFileList: setDocFileList,fileList: docFileList,setChatlist:setCurDocChatList}
          } else if (funName=='code') {
              return {setCurFileId:setCurcodeFileId,curFileId:curcodeFileId, setFileList: setCodeFileList,fileList: codeFileList,setChatlist:setCurCodeChatList}
          }
          // chat
          return {setCurFileId:setCurchatFileId,curFileId:curchatFileId, setFileList: setChatFileList,fileList: chatFileList,setChatlist:setChatlist}
  
        }
    */
    const sessionObj = useMemo(() => {
      if (funName=='img') {
            return {setCurFileId:setCurimgFileId,curFileId:curimgFileId, setFileList: setImgFileList,fileList: imgFileList,setChatlist:setCurImgChatList}
        } else if (funName=='doc') {
            return {setCurFileId:setCurdocFileId,curFileId:curdocFileId, setFileList: setDocFileList,fileList: docFileList,setChatlist:setCurDocChatList}
        } else if (funName=='code') {
            return {setCurFileId:setCurcodeFileId,curFileId:curcodeFileId, setFileList: setCodeFileList,fileList: codeFileList,setChatlist:setCurCodeChatList}
        }
        // chat
        return {setCurFileId:setCurchatFileId,curFileId:curchatFileId, setFileList: setChatFileList,fileList: chatFileList,setChatlist:setChatlist}

      }, [funName,curchatFileId,chatFileList,curimgFileId,imgFileList,curdocFileId,docFileList,curcodeFileId,codeFileList,
        setCurdocFileId,
        setCurcodeFileId,
        setCurchatFileId,
        setCurimgFileId,
        setImgFileList,
        setDocFileList,
        setCodeFileList,
        setChatFileList,
        setCurImgChatList,
        setCurDocChatList,
        setCurCodeChatList,
        setChatlist
    ]);
   

    // 新建会话
    const addSession = (title) => {
        const newsession = { id: (funName || 'chat')+'_' + Date.now(), title: title || '' };
        sessionObj.setCurFileId(newsession);
        sessionObj.fileList.splice(0, 0, newsession);
        sessionObj.setFileList([...sessionObj.fileList]);
    };

    
    // 选择会话
    const selectSession = (id) => {
        const index = sessionObj.fileList.findIndex((item) => item.id === id);
        if (index >= 0) {
            sessionObj.setCurFileId(sessionObj.fileList[index]);
            return;
        }
        if (sessionObj.fileList.length==0) {
            sessionObj.setChatList([]);
            sessionObj.curFileId.title='';
            sessionObj.setCurFileId(sessionObj.curFileId);
        } else {
            sessionObj.setCurFileId(sessionObj.fileList[0]);
        }
        
    };

    // 更新会话标题
    const updateSession = (title) => {
        sessionObj.curFileId.title = (title && title.substring(0, 10));
        sessionObj.setCurFileId(sessionObj.curFileId);
        const index = sessionObj.fileList.findIndex((item) => item.id === sessionObj.curFileId.id);
        if (index>=0) {
            sessionObj.fileList[index]=sessionObj.curFileId;
            sessionObj.setFileList([...sessionObj.fileList]);
        }
    };

    // 删除会话
    const deleteSession = (id) => {
        const index = sessionObj.fileList.findIndex((item) => item.id === id);
        if (index >= 0) {
            sessionObj.fileList.splice(index, 1);
        }
        if (sessionObj.curFileId.id == id) {
            if (sessionObj.fileList.length==0) {
                sessionObj.setChatList([]);
                sessionObj.curFileId.title='';
                sessionObj.setCurFileId(sessionObj.curFileId);
            } else {
               // TODO 
               sessionObj.setCurFileId(sessionObj.fileList[0]);
            }
            
        }
    };
    ////

    useEffect(() => {
        clearmsg && setChatlist([]);
    }, [clearmsg]);

    useEffect(() => {
        window.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        const recognition = new window.SpeechRecognition();
        recognition.interimResults = true;
        recognition.lang = 'zh-CN';
        recognition.addEventListener('result', (e) => {
            console.log(e.results);
            const transcript = Array.from(e.results)
                .map((result) => result[0])
                .map((result) => result.transcript)
                .join('');
            //setSendMsg(sendMsg+transcript);
            // speechtxt.push(transcript);
            setSpeechtxt(transcript);

            // console.log(transcript);
        });
        recognition.addEventListener('end', () => {
            setSpeechtxt('');
            setIsspeak(false);
        });

        setSpeech(recognition);
    }, []);

    useEffect(() => {
        // console.log('speechtxt', speechtxt);
        setSearch(speechtxt);
    }, [speechtxt]);

    // 录音停止,就发送
    useEffect(() => {
        if (search && !isspeak) {
            setSendMsg(search);
            // setSearch('')
        }
    }, [isspeak]);

    useEffect(() => {
        api.post('/getaichatsubjects', {  }).then(function (response) { //subjecttype: 'openAI'
            if (response.data.data) {
                if (response.data.data.length > 0) {
                    setAIParams(response.data.data);
                    if (AIParams[0]) {
                        setSubject(AIParams[0]);
                    }
                }
            }
            // console.log(response);
        });
        api.post('/getaichatsamples', {  }).then(function (response) { //subjecttype: 'openAI'
            if (response.data.data) {
                setAISamples(response.data.data);
            }
            // console.log(response);
        });
    }, []);

    useEffect(() => {
        const md = new markdownIt({
            html: true, // 允许输出 HTML 标签,默认为 false
            linkify: true, // 自动将 URL 转换成链接,默认为 false
            typographer: true // 启用一些智能标点符号的替换规则,默认为 false
        });
        setMd(md);
    }, []);

    return (
        <Context.Provider
            value={{
                funName,
                setFunName,
                siderMemu,
                setSiderMemu,
                save,
                setSave,
                search,
                setSearch,
                sendMsg,
                setSendMsg,
                isApiLoading,
                setApiLoading,
                subjectType,
                setSubjectType,
                subject,
                setSubject,
                dissample,
                setDisSample,
                AIParams,
                setAIParams,
                AISamples,
                setAISamples,
                mutchat,
                setMutchat,
                entersend,
                setEntersend,
                clearmsg,
                setClearmsg,
                clearMsgOpen,
                setClearMsgOpen,
                chatscroll,
                setChatscroll,
                speech,
                setSpeech,
                isspeak,
                setIsspeak,
                chatlist,
                setChatlist,
                showchat,
                setShowchat,
                md,
                setMd,
                curchatFileId,
                setCurchatFileId,
                curdocFileId,
                setCurdocFileId,
                curimgFileId,
                setCurimgFileId,
                curcodeFileId,
                setCurcodeFileId,
                chatFileList,
                setChatFileList,
                docFileList,
                setDocFileList,
                imgFileList,
                setImgFileList,
                codeFileList,
                setCodeFileList,
                curDocChatList,
                setCurDocChatList,
                curImgChatList,
                setCurImgChatList,
                curCodeChatList,
                setCurCodeChatList,
                sessionObj,
                addSession,
                updateSession,
                selectSession,
                deleteSession
            }}
        >
            {children}
        </Context.Provider>
    );
};

export default FilterProvider;
