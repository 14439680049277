import PropTypes from 'prop-types';
import useFilter, { useChat } from '../../../hooks/useFilter';
// material-ui
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer, Button, useMediaQuery } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SyncIcon from '@mui/icons-material/Sync';
// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import DataList from './DataList';
import LogoSection from '../LogoSection';
import MenuCard from './MenuCard';
import { drawerWidth } from 'store/constant';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
    const { t } = useTranslation();
    const { funName, setFunName, siderMemu, setSiderMemu, sessionObj, addSession, updateSession, selectSession, deleteSession } =
        useFilter();
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const drawer = (
        <>
            <Box sx={{ display: { xs: 'block', md: 'block' } }}>
                <Box
                    sx={{
                        display: 'flex',
                        fontWeight: 800,
                        fontSize: 15,
                        justifyContent: 'center',
                        alignItems: 'center',
                        p: 2,
                        mx: 'auto'
                    }}
                >
                    {/* <LogoSection /> */}
                    {t('Home.HomeTile')}
                </Box>
            </Box>
            <BrowserView>
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: !matchUpMd ? 'calc(100vh - 36px)' : 'calc(100vh - 68px)',
                        paddingLeft: '16px',
                        paddingRight: '16px'
                    }}
                >
                    {(funName == 'chat' || funName == 'img' || funName == 'doc' || funName == 'code') && (
                        <Stack direction="row" spacing={0}>
                            <Button variant="outlined" startIcon={<SyncIcon />} onClick={() => setSiderMemu(!siderMemu)}>
                            {siderMemu? t('Home.Huihua'):t('Home.CaiDan')}
                            </Button>
                            <Button variant="outlined" startIcon={<AddIcon />} onClick={() => addSession()}>
                            {t('Home.XinChat')}
                            </Button>
                        </Stack>
                    )}
                    {(funName == 'chat' || funName == 'img' || funName == 'doc' || funName == 'code') && !siderMemu ? (
                        <DataList />
                    ) : (
                        <MenuList />
                    )}

                    {<MenuCard />}
                </PerfectScrollbar>
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }}>
                {(funName == 'chat' || funName == 'img' || funName == 'doc' || funName == 'code') && (
                        <Stack direction="row" spacing={0}>
                            <Button variant="outlined" startIcon={<SyncIcon />} onClick={() => setSiderMemu(!siderMemu)}>
                            {siderMemu?t('Home.Huihua'):t('Home.CaiDan')}
                            </Button>
                            <Button variant="outlined" startIcon={<AddIcon />} onClick={() => addSession()}>
                            {t('Home.XinChat')}
                            </Button>
                        </Stack>
                    )}
                    {(funName == 'chat' || funName == 'img' || funName == 'doc' || funName == 'code') && !siderMemu ? (
                        <DataList />
                    ) : (
                        <MenuList />
                    )}
                    {<MenuCard />}
                </Box>
            </MobileView>
        </>
    );

    const container = window !== undefined ? () => window.document.body : undefined;
    // console.log('drawerOpen',drawerOpen);
    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    //   'z-index':drawerOpen?1200:(-100),
                    visibility: drawerOpen ? '' : 'hidden',
                    '& .MuiDrawer-paper': {
                        overflow: 'scroll',
                        overflowY: 'hidden',
                        width: drawerWidth,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '0px'
                        }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default Sidebar;
