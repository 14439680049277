// assets
import { IconDashboard,IconWindmill,IconTypography,IconShadow,IconPalette } from '@tabler/icons';
import ElectricBolt from '@mui/icons-material/ElectricBolt';
import CurrencyBitcoin from '@mui/icons-material/CurrencyBitcoin';
import PersonPinCircle from '@mui/icons-material/PersonPinCircle';
import CurrencyExchange from '@mui/icons-material/CurrencyExchange';
import SourceIcon from '@mui/icons-material/Source';
import Groups from '@mui/icons-material/Groups';
import Article from '@mui/icons-material/Article';
import Language from '@mui/icons-material/Language';
import Apps from '@mui/icons-material/Apps';
import i18n from '../i18n/config';
// constant
const icons = { IconDashboard,
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    ElectricBolt,
    CurrencyBitcoin,
    SourceIcon,
    Groups,
    Apps,
    Article,
    Language };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: '',
    type: 'group',
    children: [
        {
            id: 'default',
            title: i18n.t('Menu.Chats'),
            type: 'item',
            url: 'ai/default',
            icon: icons.Groups,
            breadcrumbs: false
        },

        {
            id: 'AI-txt',
            title: i18n.t('Menu.Docs'),
            type: 'item',
            url: '/docs/default',
            icon: icons.Article,
            breadcrumbs: false
        },
        {
            id: 'Trans-txt',
            title: i18n.t('Menu.Trans'),
            type: 'item',
            url: '/trans/default',
            icon: icons.Language,
            breadcrumbs: false
        },
        {
            id: 'AI-imgs',
            title:  i18n.t('Menu.Imgs'),
            type: 'item',
            url: '/img/default',
            icon: icons.SourceIcon,
            breadcrumbs: false
        },
        // {
        //     id: 'AI-sharelist',
        //     title: i18n.t('Menu.Sharelist'),
        //     type: 'item',
        //     url: 'share/list',
        //     icon: icons.Apps,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'AI-help',
        //     title: '帮助指南',
        //     type: 'item',
        //     url: '/help/default',
        //     icon: icons.IconDashboard,
        //     breadcrumbs: false
        // },
    ]
};

export default dashboard;
