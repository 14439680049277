// assets
import { IconTypography } from '@tabler/icons';

// constant
const icons = { IconTypography };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const groups = {
    id: 'groups',
    title: '',
    type: 'group',
    children: [
        {
            id: 'groups-default',
            title: 'Groups List',
            type: 'item',
            url: '/groups/default',
            icon: icons.IconTypography,
            breadcrumbs: false
        }
    ]
};

export default groups;