// material-ui
import { Typography } from '@mui/material';
import useFilter, { useChat } from '../../../../hooks/useFilter'; 
import React, { useMemo } from 'react';
// project imports
import NavGroup from './NavGroup';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const DataList = () => {
    // const { showchat, setShowchat } = useChat();
    const {
        sessionObj,
    } = useFilter();

    const navItems = useMemo(() => {
        return {
            id: 'dashboard',
            title: '',
            type: 'group',
            children: sessionObj?.fileList || []
        };
    }, [sessionObj]);

    return (
        <>
            <NavGroup item={navItems}  />
        </>
    );
};

export default DataList;
