import PropTypes from 'prop-types';
import React, { useState, useRef, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MainCard from 'ui-component/cards/MainCard';
// material-ui
import ElectricBolt from '@mui/icons-material/ElectricBolt';
import DoneIcon from '@mui/icons-material/Done';
import { useTheme, styled } from '@mui/material/styles';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';

import {
    Avatar,
    Box,
    Stack,
    Typography,
    ButtonBase,
    Card,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    Divider,
    ButtonGroup,
    Button,
    ListItemText,
    InputAdornment,
    OutlinedInput,
    ClickAwayListener,
    Popper,
    Paper
} from '@mui/material';

// third-party
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';

import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import Transitions from 'ui-component/extended/Transitions';

// assets
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { IconAdjustmentsHorizontal, IconX } from '@tabler/icons';
import MessageIcon from '@mui/icons-material/Message';

import { shouldForwardProp } from '@mui/system';
// import { openAIParams } from '../../../../utils/aiapi';
import useFilter, { useSpeech } from '../../../../hooks/useFilter';
import useOpenPage from '../../../../hooks/useOpenPage';
// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
    zIndex: 1100,
    width: '99%',
    top: '-55px !important',
    padding: '0 12px',
    [theme.breakpoints.down('sm')]: {
        padding: '0 10px'
    }
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
    // width: 534,
    width: '100%',
    marginLeft: 5,
    paddingLeft: 16,
    paddingRight: 16,
    '& input': {
        background: 'transparent !important',
        paddingLeft: '4px !important'
    },
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        marginLeft: 4,
        background: '#fff'
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginLeft: 4,

        background: '#fff'
    }
}));

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    '&:hover': {
        background: theme.palette.secondary.dark,
        color: theme.palette.secondary.light
    }
}));

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ value, setValue, popupState, onClick, ref }) => {
    const theme = useTheme();
    const { openpage } = useOpenPage();
    // const {tip,setTip} = useState();
    const tip = useMemo(() => {
        // if (openpage == 'Chain') {
        //     return '输入你咨询的问题和知识，翻译，代码实现描述，写作等....';
        // }
        return '这里输入你信息....';
    }, [openpage]);
    return (
        <OutlineInputStyle
            id="input-search-header"
            multiline={true}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder={tip}
            startAdornment={
                <InputAdornment position="start">
                    <MessageIcon stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                </InputAdornment>
            }
            endAdornment={
                <InputAdornment position="end">
                    <ButtonBase sx={{ borderRadius: '12px' }} aria-haspopup="true" onClick={onClick}>
                        <HeaderAvatarStyle variant="rounded">
                            <IconAdjustmentsHorizontal stroke={1.5} size="1.3rem" />
                        </HeaderAvatarStyle>
                    </ButtonBase>
                    <Box sx={{ ml: 2 }}>
                        <ButtonBase sx={{ borderRadius: '12px' }}>
                            <Avatar
                                variant="rounded"
                                sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.mediumAvatar,
                                    background: theme.palette.orange.light,
                                    color: theme.palette.orange.dark,
                                    '&:hover': {
                                        background: theme.palette.orange.dark,
                                        color: theme.palette.orange.light
                                    }
                                }}
                                {...bindToggle(popupState)}
                            >
                                <IconX stroke={1.5} size="1.3rem" />
                            </Avatar>
                        </ButtonBase>
                    </Box>
                </InputAdornment>
            }
            aria-describedby="search-helper-text"
            inputProps={{ 'aria-label': 'weight' }}
        />
    );
};

MobileSearch.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func,
    popupState: PopupState
};

// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = () => {
    const theme = useTheme();
    // const [value, setValue] = useState('');
    const customization = useSelector((state) => state.customization);
    const anchorFilterRef = useRef(null);

    const { speech, isspeak, setIsspeak } = useSpeech();
    const { search, setSearch,entersend, subjectType,setSendMsg, setSubjectType, subject, setSubject, AIParams } = useFilter();

    const { openpage } = useOpenPage();
    const [filteropen, setFilterOpen] = useState(false);
    // const [isspeak, setIsspeak] = useState(false);
    const [maxrow, setMaxrow] = useState(1);

    // const {tip,setTip} = useState();

    const handleFilterClose = (event) => {
        if (anchorFilterRef.current && anchorFilterRef.current.contains(event.target)) {
            return;
        }
        setFilterOpen(false);
    };

    const handleSubject = (itemparam) => {
        setSubjectType('openAI');
        setSubject(itemparam);
        setFilterOpen(false);
    };
    const handleFilter = () => {
        setFilterOpen(!filteropen);
        console.log(filteropen);
    };
    const handleSpeech = () => {
        setIsspeak(!isspeak);
    };
    useEffect(() => {
        if (!speech) return;
        if (isspeak) {
            speech.stop();
            speech.start();
        } else {
            speech.stop();
        }
    }, [isspeak]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && entersend) {
            event.preventDefault();
            setSendMsg(search);
            setSearch('');
        }
    };

    const tip = useMemo(() => {
        // if (openpage == 'Chain') {
        //     return '输入你咨询的问题和知识，翻译，代码实现描述，写作等....';
        // }
        return '这里输入你信息....';
    }, [openpage]);
    return (
        <>
            <Box sx={{ display: { xs: 'none', md: 'none' } }}>
                <PopupState variant="popper" popupId="demo-popup-popper">
                    {(popupState) => (
                        <>
                            <Box sx={{ ml: 2 }}>
                                <ButtonBase sx={{ borderRadius: '12px' }}>
                                    <HeaderAvatarStyle variant="rounded" {...bindToggle(popupState)}>
                                        <MessageIcon stroke={1.5} size="1.2rem" />
                                    </HeaderAvatarStyle>
                                </ButtonBase>
                            </Box>
                            <PopperStyle {...bindPopper(popupState)} transition>
                                {({ TransitionProps }) => (
                                    <>
                                        <Transitions type="zoom" {...TransitionProps} sx={{ transformOrigin: 'center left' }}>
                                            <Card
                                                sx={{
                                                    background: '#fff',
                                                    [theme.breakpoints.down('sm')]: {
                                                        border: 0,
                                                        boxShadow: 'none'
                                                    }
                                                }}
                                            >
                                                <Box sx={{ p: 2 }}>
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item xs>
                                                            <MobileSearch
                                                                value={search}
                                                                setValue={setSearch}
                                                                onClick={handleFilter}
                                                                popupState={popupState}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Card>
                                        </Transitions>
                                    </>
                                )}
                            </PopperStyle>
                        </>
                    )}
                </PopupState>
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'block' } }}>
                <OutlineInputStyle
                    multiline={true}
                    id="input-search-header"
                    value={search}
                    maxRows={maxrow}
                    onKeyDown={handleKeyDown}
                    onBlur={(e) => setMaxrow(1)}
                    onFocus={(e) => setMaxrow(100)}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder={tip}
                    startAdornment={
                        <InputAdornment position="start" onClick={handleSpeech}>
                            {/* <MessageIcon stroke={1.5} size="1rem"  onClick={handleClear}  color={theme.palette.grey[500]} /> */}
                            <KeyboardVoiceIcon
                                stroke={1.5}
                                size="1rem"
                                sx={{ color: isspeak ? theme.palette.orange.dark : theme.palette.grey[500] }}
                            />
                        </InputAdornment>
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            <ButtonBase
                                variant="outlined"
                                ref={anchorFilterRef}
                                aria-controls={filteropen ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                
                                onClick={handleFilter}
                                color="primary"
                                sx={{ borderRadius: '12px' }}
                            >
                                <HeaderAvatarStyle variant="rounded">
                                    <KeyboardDoubleArrowDownIcon stroke={1.5} size="1.3rem" />
                                </HeaderAvatarStyle>
                            </ButtonBase>
                        </InputAdornment>
                    }
                    aria-describedby="search-helper-text"
                    inputProps={{ 'aria-label': 'weight' }}
                />
            </Box>
            <Popper
                placement="bottom-end"
                open={filteropen}
                anchorEl={anchorFilterRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={filteropen} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleFilterClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box sx={{ p: 1 }}>
                                        <Typography variant="h4">选择主题:</Typography>
                                    </Box>
                                    <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(80vh - 70px)', overflowX: 'hidden' }}>
                                        <Box sx={{ p: 1 }}>
                                            <List
                                                component="nav"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 350,
                                                    minWidth: 300,
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: {
                                                        minWidth: '100%'
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 0.5
                                                    }
                                                }}
                                            >
                                                {AIParams.map((itemparam, index) => (
                                                    <ListItemButton
                                                        key={index}
                                                        sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                        selected={subject === itemparam.subject}
                                                        onClick={() => {
                                                            handleSubject(itemparam);
                                                        }}
                                                    >
                                                        <ListItemIcon>
                                                            <Avatar
                                                                sx={{
                                                                    mr: 1,
                                                                    bgcolor: theme.palette.primary[800],
                                                                    color: theme.palette.grey[50]
                                                                }}
                                                            >
                                                                {index + 1}
                                                            </Avatar>
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Grid container spacing={1} justifyContent="space-between">
                                                                    <Grid item>
                                                                        <Typography variant="subtitle1">{itemparam.title}</Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        {subject === itemparam.subject && (
                                                                            <DoneIcon color="success" stroke={1.5} size="1rem" />
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                            secondary={
                                                                <React.Fragment>
                                                                    <Typography
                                                                        sx={{ display: 'inline' }}
                                                                        component="span"
                                                                        variant="subtitle2"
                                                                    >
                                                                        {itemparam.desc}
                                                                    </Typography>
                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                ))}
                                            </List>
                                        </Box>
                                    </PerfectScrollbar>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default SearchSection;
