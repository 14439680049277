import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar,Alert,Snackbar, Box, ButtonBase } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';

import useTipMsg from '../../../hooks/useTipMsg';
import {useChat} from '../../../hooks/useFilter';

// assets
import { IconMenu2 } from '@tabler/icons';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {

    const theme = useTheme();
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const { tipopen, setTipopen, tiptext, setTiptext, tiptype, setTiptype } = useTipMsg();

    const handleClose =()=>{
        setTipopen(false)
    }

    return (
        <>
            {/* logo & toggler button */}
            <Snackbar
                open={tipopen}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={tiptype} sx={{ width: '100%' }}>
                   {tiptext}
                </Alert>
            </Snackbar>
            <Box
                sx={{
                    width: 'auto',
                    // width: 210,
                     display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                {leftDrawerOpened && (<Box component="span" sx={{ width: 180, display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    {/* <LogoSection /> */}
                </Box>)}
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header search */}
            
            <Box sx={{ flexGrow: 1 }} ><SearchSection /></Box>
            

            {/* notification & profile */}
            {/* <NotificationSection /> */}
             <ProfileSection /> 
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
