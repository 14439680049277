import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import Setting from '../../../Setting';
import useFilter from '../../../../hooks/useFilter';
import useKeyConfig from '../../../../hooks/useKeyConfig';
import { API_MODEL } from '../../../../utils/aiapi';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    FormControlLabel,
    Divider,
    Card,
    Button,
    CardContent,
    Grid,
    LinearProgress,
    List,
    ListItem,
    Checkbox,
    ListItemText,
    Typography,
    linearProgressClasses
} from '@mui/material';

import SettingsIcon from '@mui/icons-material/Settings';

// assets
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import qunqrcode from '../../../../assets/images/qun.png';
// styles
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 30,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#fff'
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.primary.main
    }
}));

const CardStyle = styled(Card)(({ theme }) => ({
    background: theme.palette.primary.light,
    marginBottom: '22px',
    overflow: 'hidden',
    position: 'relative'
    // '&:after': {
    //     content: '""',
    //     position: 'absolute',
    //     width: '157px',
    //     height: '157px',
    //     background: theme.palette.primary[200],
    //     borderRadius: '50%',
    //     top: '-105px',
    //     right: '-96px'
    // }
}));

// ==============================|| PROGRESS BAR WITH LABEL ||============================== //

function LinearProgressWithLabel({ value, ...others }) {
    const theme = useTheme();

    return (
        <Grid container direction="column" spacing={1} sx={{ mt: 1.5 }}>
            <Grid item>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h6" sx={{ color: theme.palette.primary[800] }}>
                            Progress
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" color="inherit">{`${Math.round(value)}%`}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <BorderLinearProgress variant="determinate" value={value} {...others} />
            </Grid>
        </Grid>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number
};

// ==============================|| SIDEBAR MENU Card ||============================== //

const MenuCard = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const { subjectType, setSubjectType, subject, setSubject, mutchat, setMutchat,entersend,setEntersend} = useFilter();
    const { keyConfig, setkeyConfig } = useKeyConfig();
    return (
        <CardStyle>
            <CardContent sx={{ p: 2 }}>
                <List sx={{ p: 0, m: 0 }}>
{/*                     <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }}>
                        <ListItemText
                            sx={{ mt: 0 }}
                            primary={
                                <Typography variant="subtitle1" sx={{ color: theme.palette.primary[800] }}>
                                    {subject.title}
                                </Typography>
                            }
                            secondary={<Typography variant="caption">{subject.desc}</Typography>}
                        />
                    </ListItem> */}
                    <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }}>
                    <img src={qunqrcode} width="130" alt={t('Home.QunImg')}/>
                    </ListItem>
                    <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }}>
                        <ListItemText
                            sx={{ mt: 0 }}
                            secondary={<Typography variant="subtitle2">{t('Home.QunDesc')+'wen407329'}</Typography>}
                        />
                    </ListItem>
                    <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }}>
                        <ListItemText
                            sx={{ mt: 0 }}
                            primary={
                                <Typography variant="subtitle1" sx={{ color: theme.palette.primary[800] }}>
                                    {t('Home.JieRuMuShi')}
                                </Typography>
                            }
                            secondary={
                                <Typography variant="subtitle2">
                                    {t('Home.'+API_MODEL.find((item) => item.id === keyConfig.openAI.type)?.label)}
                                </Typography>
                            }
                        />
                    </ListItem>


                </List>
                <Divider />

                <Button variant="contained" onClick={() => setOpen(true)} startIcon={<SettingsIcon />}>
                {t('Home.SheZhiKey')}
                </Button>

                {/* <FormControlLabel control={<Checkbox checked={dissample} onChange={() => setDisSample(!dissample)} />} label="显示例子" /> */}
                <FormControlLabel control={<Checkbox checked={mutchat} onChange={() => setMutchat(!mutchat)} />} label={t('Home.DaiHuihua')} />
                <FormControlLabel control={<Checkbox checked={entersend} onChange={() => setEntersend(!entersend)} />} label={t('Home.HuiCheFaSong')} />
                <Setting open={open} setOpen={setOpen} />
            </CardContent>
        </CardStyle>
    );
};

export default MenuCard;
