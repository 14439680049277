export const API_URL = {
    openAI: {
        direct: ' https://api.openai.com/',//   https://api.openai.com/
        agent: `${process.env.REACT_APP_URL}aiapi`,
        comm: `${process.env.REACT_APP_URL}aiapi`,
        test: `${process.env.REACT_APP_URL}aiapi`
    },
    openAIStream: {
        direct: 'https://api.openai.com/',
        agent: `${process.env.REACT_APP_URL}aiapistream`,
        comm: `${process.env.REACT_APP_URL}aiapistream`,
        test: `${process.env.REACT_APP_URL}aiapistream`
    },
    // 维友模型API 不用连接方式的连接点
    weiYouAI: {
      direct: `${process.env.REACT_APP_URL}gptaiapi/weiYouAI/`, // , 'http://weiyouapi.aichats.top:58080/' // 'http://183.240.197.189:58080/'  gptaiapi/
      agent: `${process.env.REACT_APP_URL}aiapi`,
      comm: `${process.env.REACT_APP_URL}aiapi`,
      test: `${process.env.REACT_APP_URL}aiapi`
  },
    // 图片
    openAIImg: {
        direct: 'https://api.openai.com/',
        agent: `${process.env.REACT_APP_URL}imgaiapi`,
        comm: `${process.env.REACT_APP_URL}imgaiapi`,
        test: `${process.env.REACT_APP_URL}imgaiapi`
    },
    txAIImg: {
        direct: `${process.env.REACT_APP_URL}imgtxaiapi`,
        agent: `${process.env.REACT_APP_URL}imgtxaiapi`,
        comm: `${process.env.REACT_APP_URL}imgtxaiapi`,
        test: `${process.env.REACT_APP_URL}imgtxaiapi`
    }

    /*     openAI: {
        direct: 'https://api.openai.com/v1/completions',
        agent: 'http://127.0.0.1:7001/aiapi',
        comm: 'http://127.0.0.1:7001/aiapi',
        test: 'http://127.0.0.1:7001/aiapi'
    }  */
};

export const API_MODEL = [
    { id: 'test', label: 'label1', desc: 'desc1' }, //多语言环境 获取具体描述
    {
        id: 'direct',
        label: 'label2',
        desc: 'desc2'
    },
    {
        id: 'agent',
        label: 'label3',
        desc: 'desc3'
    },
    {
        id: 'comm',
        label: 'label4',
        desc: 'desc4'
    }
];

export const openAIParams = [
    {
        subjecttype: 'openAI',
        index: 1,
        subject: 'chat',
        title: '对话聊天',
        url: 'v1/chat/completions',
        desc: '不限主题，开放式对话，助理乐于助人、富有创意、聪明而且非常友好。',
        sample: [],
        modelparms: {
            model: 'gpt-3.5-turbo-16k-0613',
            messages: [],
            temperature: 0.9,
            max_tokens: 1000,
            top_p: 1,
            frequency_penalty: 0.0,
            presence_penalty: 0.6
        }
    },
    {
        subjecttype: 'openAI',
        index: 2,
        subject: 'mulu',
        title: '写论文大纲',
        systemmsg: [{ role: 'system', content: '为研究主题生成大纲' }],
        url: 'v1/chat/completions',
        desc: '为研究主题生成大纲',
        sample: [],
        // 写论文 为研究主题生成大纲。
        // 例子：为关于尼古拉特斯拉及其对技术的贡献的文章创建一个大纲
        modelparms: {
            model: 'gpt-3.5-turbo-16k-0613',
            temperature: 0.3,
            max_tokens: 2000,
            top_p: 1.0,
            frequency_penalty: 0.0,
            presence_penalty: 0.0
        }
    },
    // 文案编写
    {
        subjecttype: 'openAI',
        index: 3,
        subject: 'docs',
        title: '写营销文案',
        url: 'v1/chat/completions',
        systemmsg: [{ role: 'system', content: '根据产品的功能、适应人群和关键字,写营销文案' }],
        desc: '根据产品的功能、适应人群和关键字,写营销文案',
        sample: [],
        modelparms: {
            model: 'gpt-3.5-turbo-16k-0613',
            temperature: 0.4,
            max_tokens: 3000,
            top_p: 1.0,
            frequency_penalty: 0.5,
            presence_penalty: 0.0
        }
    },

    //提供主题并获取学习笔记。
    {
        subjecttype: 'openAI',
        index: 4,
        subject: 'codejiex',
        title: '解释代码',
        url: 'v1/chat/completions',
        systemmsg: [{ role: 'system', content: '为研究主题生成大纲' }],
        desc: '提解释一段复杂的代码,能代码生成描述语言',
        sample: [],
        modelparms: {
            model: 'gpt-3.5-turbo-16k-0613',
            temperature: 0,
            max_tokens: 3000,
            top_p: 1.0,
            frequency_penalty: 0.0,
            presence_penalty: 0.0
        }
    },
    // 推荐书和小说。
    {
        subjecttype: 'openAI',
        index: 5,
        subject: 'javascriptcode',
        title: 'JavaScript 助手',
        url: 'v1/chat/completions',
        systemmsg: [{ role: 'system', content: '可以回答有关使用 JavaScript 的问题' }],
        desc: '可以回答有关使用 JavaScript 的问题',
        sample: [],
        modelparms: {
            model: 'gpt-3.5-turbo-16k-0613',
            temperature: 0,
            max_tokens: 1060,
            top_p: 1.0,
            frequency_penalty: 0.5,
            presence_penalty: 0.0
        }
    },
    {
        subjecttype: 'openAI',
        index: 6,
        subject: 'xuexi',
        title: '写作文',
        url: 'v1/chat/completions',
        systemmsg: [{ role: 'system', content: '提供作文主题和作文大纲写作' }],
        desc: '提供作文主题和大纲写作文',
        sample: [],
        modelparms: {
            model: 'gpt-3.5-turbo-16k-0613',
            temperature: 0.5,
            max_tokens: 3000,
            top_p: 1.0,
            frequency_penalty: 0.52,
            presence_penalty: 0.5
        }
    },
    {
        subjecttype: 'openAI',
        index: 7,
        subject: 'yufa',
        title: '语法修正',
        url: 'v1/chat/completions',
        systemmsg: [{ role: 'system', content: '语法修正，将句子改成标准语法' }],
        desc: '将句子改成标准语法',
        sample: [],
        modelparms: {
            model: 'gpt-3.5-turbo-16k-0613',
            temperature: 0,
            max_tokens: 3000,
            top_p: 1.0,
            frequency_penalty: 0.0,
            presence_penalty: 0.0
        }
    }
];

export const setLocalValue = (key, value) => {
    if (window && window.localStorage) {
        try {
            window.localStorage.setItem(key, JSON.stringify(value));
        } catch (err) {
            return;
        }
    }
};

export const getLocalValue = (key) => {
    if (window && window.localStorage) {
        if (!window.localStorage.getItem(key)) {
            return null;
        }
        try {
            return JSON.parse(window.localStorage.getItem(key));
        } catch (err) {
            return null;
        }
    } else {
        return null;
    }
};

/**
 * 
 * 

curl https://api.openai.com/v1/images/generations \
  -H 'Content-Type: application/json' \
  -H "Authorization: Bearer $OPENAI_API_KEY" \
  -d '{
    "prompt": "a white siamese cat",
    "n": 1,
    "size": "1024x1024"
  }'
  
 *
 const axios = require('axios').default;

// Make a request for a user with a given ID
axios.get('/user?ID=12345')
  .then(function (response) {
    // handle success
    console.log(response);
  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  .then(function () {
    // always executed
  });

// Optionally the request above could also be done as
axios.get('/user', {
    params: {
      ID: 12345
    }
  })
  .then(function (response) {
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  })
  .then(function () {
    // always executed
  });  


  axios.post('/user', {
    firstName: 'Fred',
    lastName: 'Flintstone'
  })
  .then(function (response) {
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });



// Want to use async/await? Add the `async` keyword to your outer function/method.
async function getUser() {
  try {
    const response = await axios.get('/user?ID=12345');
    console.log(response);
  } catch (error) {
    console.error(error);
  }
} 
// GET request for remote image in node.js
axios({
  method: 'get',
  url: 'http://bit.ly/2mTM3nY',
  responseType: 'stream'
})
  .then(function (response) {
    response.data.pipe(fs.createWriteStream('ada_lovelace.jpg'))
  });



  // Send a GET request (default method)
axios('/user/12345');



axios.request(config)
axios.get(url[, config])
axios.delete(url[, config])
axios.head(url[, config])
axios.options(url[, config])
axios.post(url[, data[, config]])
axios.put(url[, data[, config]])
axios.patch(url[, data[, config]])


// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  });
*/
