import { useContext } from 'react';
import { Context } from '../contexts/FilterProvider';

function useFilter() {
    const {
        funName,
        setFunName,
        siderMemu,
        setSiderMemu,
        save,
        setSave,
        search,
        setSearch,
        sendMsg,
        setSendMsg,
        isApiLoading,
        setApiLoading,
        subjectType,
        setSubjectType,
        subject,
        setSubject,
        AIParams,
        AISamples,
        dissample,
        mutchat,
        setMutchat,
        entersend,
        setEntersend,
        setDisSample,
        setClearmsg,
        clearmsg,
        clearMsgOpen,
        setClearMsgOpen,
        chatscroll,
        setChatscroll,
        chatlist,
        setChatlist,
        showchat,
        setShowchat,
        curchatFileId,
        setCurchatFileId,
        curdocFileId,
        setCurdocFileId,
        curimgFileId,
        setCurimgFileId,
        curcodeFileId,
        setCurcodeFileId,
        chatFileList,
        setChatFileList,
        docFileList,
        setDocFileList,
        imgFileList,
        setImgFileList,
        codeFileList,
        setCodeFileList,
        curDocChatList,
        setCurDocChatList,
        curImgChatList,
        setCurImgChatList,
        curCodeChatList,
        setCurCodeChatList,
        sessionObj,
        addSession,
        updateSession,
        selectSession,
        deleteSession
    } = useContext(Context);
    return {
        funName,
        setFunName,
        siderMemu,
        setSiderMemu,
        save,
        setSave,
        search,
        setSearch,
        sendMsg,
        setSendMsg,
        isApiLoading,
        setApiLoading,
        subjectType,
        setSubjectType,
        subject,
        setSubject,
        AIParams,
        AISamples,
        dissample,
        setDisSample,
        mutchat,
        setMutchat,
        entersend,
        setEntersend,
        setClearmsg,
        clearmsg,
        clearMsgOpen,
        setClearMsgOpen,
        chatscroll,
        setChatscroll,
        chatlist,
        setChatlist,
        showchat,
        setShowchat,
        curchatFileId,
        setCurchatFileId,
        curdocFileId,
        setCurdocFileId,
        curimgFileId,
        setCurimgFileId,
        curcodeFileId,
        setCurcodeFileId,
        chatFileList,
        setChatFileList,
        docFileList,
        setDocFileList,
        imgFileList,
        setImgFileList,
        codeFileList,
        setCodeFileList,
        curDocChatList,
        setCurDocChatList,
        curImgChatList,
        setCurImgChatList,
        curCodeChatList,
        setCurCodeChatList,
        sessionObj,
        addSession,
        updateSession,
        selectSession,
        deleteSession
    };
}

export function useSpeech() {
    const { speech, setSpeech, isspeak, setIsspeak } = useContext(Context);
    return { speech, setSpeech, isspeak, setIsspeak };
}
export function useChat() {
    const { showchat, setShowchat } = useContext(Context);
    return { showchat, setShowchat };
}
export function useMd() {
    const { md, setMd } = useContext(Context);
    return { md, setMd };
}

export default useFilter;
