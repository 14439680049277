import PropTypes from 'prop-types';
import React, { useState, useRef, useMemo, useEffect } from 'react';

import MainCard from 'ui-component/cards/MainCard';

import { useTheme, styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import useFilter from '../../hooks/useFilter';
import DialogTitle from '@mui/material/DialogTitle';
const ClearMsg = () => {
    const theme = useTheme();
    const {  setClearmsg, clearmsg,clearMsgOpen, setClearMsgOpen } = useFilter();
   // const [clearMsgOpen, setClearMsgOpen] = useState(false);
    const handleClearClose = () => {
        setClearMsgOpen(false);
    };
    const handleClearOk = () => {
        setClearmsg(!clearmsg);
        setClearMsgOpen(false);
    };
    /*
    useEffect(() => {
         setClearMsgOpen(!clearMsgOpen);
    }, [clearMsgOpen]);
  */
    return (
        <>
            <Dialog
            open={clearMsgOpen}
            onClose={handleClearClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle >{'确定清空你的对话记录吗?'}</DialogTitle>
            <DialogActions>
                <Button onClick={handleClearClose}>取消</Button>
                <Button onClick={handleClearOk}>清空</Button>
            </DialogActions>
        </Dialog>
        </>);
}
export default ClearMsg;