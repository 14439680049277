import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

import PubstateProvider from './contexts/PubstateProvider';
import FilterProvider from './contexts/FilterProvider';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/config';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';


// project imports
import NavigationScroll from 'layout/NavigationScroll';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <I18nextProvider i18n={i18n}>
            <ThemeProvider theme={themes(customization)}>      
                <CssBaseline />
                <PubstateProvider>
                <FilterProvider>
                <NavigationScroll>
                <Routes />
                </NavigationScroll>
                </FilterProvider>
                </PubstateProvider>
                
            </ThemeProvider>
            </I18nextProvider>
        </StyledEngineProvider>
    );
};

export default App;
