import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));


// utilities routing
// const Groups = Loadable(lazy(() => import('views/groups/index')));
// const ChatCard35 = Loadable(lazy(() => import('views/chat35/index')));
const ChatCardStream = Loadable(lazy(() => import('views/chatstream/index')));
const CommChats= Loadable(lazy(() => import('views/commchats/index')));


// const UpdateGroup = Loadable(lazy(() => import('views/groups/UpdateGroup')));
const CreateImg = Loadable(lazy(() => import('views/imgs/CreateImg')));
const ImgChat = Loadable(lazy(() => import('views/imgs/ImgChat')));
const CreateDocs = Loadable(lazy(() => import('views/docs/CreateDocs')));
const Editor = Loadable(lazy(() => import('views/docs/Editor')));

const Share = Loadable(lazy(() => import('views/share/index')));
const AllShareList = Loadable(lazy(() => import('views/share/AllShareList')));
const ShareOne = Loadable(lazy(() => import('views/share/ShareOne')));

const TransDocs = Loadable(lazy(() => import('views/trans/TransDocs')));
const TransJson = Loadable(lazy(() => import('views/trans/TransJson')));

const InputChat = Loadable(lazy(() => import('views/aiinput/inputChat')));


const MyCollects = Loadable(lazy(() => import('views/mycollects/index')));
const MySubmits = Loadable(lazy(() => import('views/mysubmits/index')));



// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <CommChats />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <CommChats />
                }
            ]
        },
        {
            path: 'ai',
            children: [
                {
                    path: 'default',
                    element: <CommChats />
                },
                {
                    path: 'subject/:_subject',
                    element: <CommChats />
                }
            ]
        },
        {
            path: 'chat',
            children: [
                {
                    path: 'default',
                    element: <CommChats />
                },
                {
                    path: 'subject/:_subject',
                    element: <CommChats />
                }
            ]
        },
        {
            path: 'chatstream',
            children: [
                {
                    path: 'default',
                    element: <ChatCardStream />
                },
                {
                    path: 'subject/:_subject',
                    element: <ChatCardStream />
                }
            ]
        },
        {
            path: 'commchats',
            children: [
                {
                    path: 'default',
                    element: <CommChats />
                },
                {
                    path: 'subject/:_subject',
                    element: <CommChats />
                }
            ]
        },
        
        {
            path: 'share',
            children: [
                {
                    path: 'default',
                    element: <Share />
                },
                {
                    path: 'list',
                    element: <AllShareList />
                },
                {
                    path: 'shareone/:id',
                    element: <ShareOne />
                }
             
            ]
        },
        {
            path: 'img',
            children: [
                {
                    path: 'default',
                    element: <ImgChat />
                },
                {
                    path: 'chat',
                    element: <ImgChat />
                }
            ]
        },
        {
            path: 'docs',
            children: [
                {
                    path: 'default',
                    element: <Editor />
                },
                {
                    path: 'edit',
                    element: <Editor />
                }
            ]
        },
        
        {
            path: 'trans',
            children: [
                {
                    path: 'default',
                    element: <TransDocs />
                },
            ]
        },

        {
            path: 'trans',
            children: [
                {
                    path: 'json',
                    element: <TransJson />
                },
            ]
        },
        {
            path: 'input',
            children: [
                {
                    path: 'json',
                    element: <InputChat />
                },
            ]
        },
        

        {
            path: 'help',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                },

            ]
        },
        {
            path: 'my',
            children: [
                {
                    path: 'collects',
                    element: <MyCollects />
                },
                {
                    path: 'collects',
                    element: <MySubmits />
                }
            ]
        },

    ]
};

export default MainRoutes;
