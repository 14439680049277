import React, { createContext, useCallback, useState, useEffect } from 'react';
import {getLocalValue,setLocalValue} from '../../utils/aiapi'


export const Context = createContext({ keyConfig: {}, setkeyConfig: null });
import { api } from '../../utils/api';
const PubstateProvider = ({ children }) => {
   
    
    const [modelType, setModelType] = useState('openAI'); // 聊天模型类型

    const [tipopen, setTipopen] = useState(false); // 提示k
    const [tiptext, setTiptext] = useState(''); // 内容
    const [tiptype, setTiptype] = useState('error'); //提示类型
    const [openpage, setOpenpage] = useState('Chain');
    const [keyConfig, setkeyConfig] = useState({ openAI: { type: 'test',
    key: 'test', // 当前类型key
    directkey:'', // 直连key
    agentkey:'',
    commkey:'comm', // 通用key  //付费限制
    testkey:'test', // 测试key 有测试次数限制
   },weiYouAI:{directkey:''},...getLocalValue('keyConfig')}); // 配置key参数  
    
    const [types, setTypes] = useState([]);
    const [login, setLogin] = useState(false); // 标志是否需要登陆
    const [token, setToken] = useState(''); // 登陆token信息

    const openTip = useCallback(
        (tiptext, tiptype) => {
            setTipopen(true);
            setTiptext(tiptext);
            setTiptype(tiptype);
        },
        [tipopen, tiptext, tiptype]
    );
    
    useEffect(() => {
       setLocalValue('keyConfig',keyConfig);
    }, [keyConfig]);

    useEffect(() => {
        /*   api.get(
      '/chainsselect',{}
  ).then(function (response) {
    setChainsselect(response.data.data);
     // console.log(response);
  });
  api.get(
    '/types',{}
).then(function (response) {
  setTypes(response.data.data);
   // console.log(response);

}); */
    }, []);

    return (
        <Context.Provider
            value={{
                modelType,
                setModelType,

                tipopen,
                setTipopen,
                tiptext,
                setTiptext,
                tiptype,
                setTiptype,
                openTip,
                openpage,
                setOpenpage,
                keyConfig,
                setkeyConfig,
                types,
                setTypes,
                login,
                setLogin,
                token,
                setToken
            }}
        >
           
            {children}
        </Context.Provider>
    );
};

export default PubstateProvider;
